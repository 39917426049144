/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/flatpickr@4.6.13/dist/flatpickr.min.css
 * - /npm/basictable@2.0.4/dist/css/basictable.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/magnific-popup@1.2.0/dist/magnific-popup.min.css
 * - /npm/lightbox2@2.11.4/dist/css/lightbox.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
